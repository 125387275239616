<template>
  <v-container>
    <!-- <v-btn @click="fetch" :loading="api.isLoading">Fetch</v-btn> -->
    <div v-if="api.loading" class="text-center pa-5">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div>
      <FilterSchool :callbackSelect="selectSchool" :schools="schools" />
    </div>
    <v-row v-if="data && !api.loading">
      <v-col cols="12" md="8">
        <v-card>
          <v-simple-table dense>
            <tbody>
              <template v-if="data.event">
                <tr>
                  <td>{{ $t("model.name.festival") }}</td>
                  <td>{{ data.event.Festival.name }}</td>
                  <td class="text-right">
                    <JsonExcel
                      name="festival.xls"
                      worksheet="Hamochi"
                      :data="festival"
                    >
                      <v-btn x-small color="primary" dark>
                        {{ $t("action.download") }}
                      </v-btn>
                    </JsonExcel>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("model.name.event") }}</td>
                  <td>{{ data.event.name }}</td>
                  <td class="text-right">
                    <JsonExcel
                      name="event.xls"
                      worksheet="Hamochi"
                      :data="event"
                    >
                      <v-btn x-small color="primary" dark>
                        {{ $t("action.download") }}
                      </v-btn>
                    </JsonExcel>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("model.prop.dateStart") }}</td>
                  <td>
                    {{ $moment(data.event.timestampStart).format("LLL") }}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>{{ $t("model.prop.dateEnd") }}</td>
                  <td>{{ $moment(data.event.timestampEnd).format("LLL") }}</td>
                  <td></td>
                </tr>
              </template>
              <template v-if="data.school">
                <tr>
                  <td>{{ $t("model.name.school") }}</td>
                  <td>{{ data.school.name }}</td>
                  <td class="text-right">
                    <JsonExcel
                      name="school.xls"
                      worksheet="Hamochi"
                      :data="school"
                    >
                      <v-btn x-small color="primary" dark>
                        {{ $t("action.download") }}
                      </v-btn>
                    </JsonExcel>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mb-3">
          <v-simple-table dense>
            <tbody>
              <template
                v-for="(d, i) in [
                  'checkpoints',
                  'classrooms',
                  'students',
                  'participants',
                  'submissions',
                  'questions',
                  'records',
                  'labRecords',
                ]"
              >
                <tr v-if="data[d]" :key="i">
                  <td>{{ $t(`model.name.${d}`) }}</td>
                  <td class="text-right">{{ data[d].length }}</td>
                  <td class="text-right">
                    <JsonExcel :name="d" worksheet="Hamochi" :data="data[d]">
                      <v-btn x-small color="primary" dark>
                        {{ $t("action.download") }}
                      </v-btn>
                    </JsonExcel>
                  </td>
                </tr>
              </template>
              <tr v-if="activity">
                <td>{{ $t(`model.name.activity`) }}</td>
                <td class="text-right">{{ activity.length }}</td>
                <td class="text-right">
                  <JsonExcel
                    name="activity"
                    worksheet="Hamochi"
                    :data="activity"
                  >
                    <v-btn x-small color="primary" dark>
                      {{ $t("action.download") }}
                    </v-btn>
                  </JsonExcel>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab key="0">All</v-tab>
      <v-tab key="1">By Gender</v-tab>
      <v-tab key="2">By Platform</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TableTrend
          v-if="data && data.activity"
          :activity="activity"
          :data="data"
        ></TableTrend>
        <div v-else class="text-center pa-5">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-tab-item>
      <v-tab-item>
        <template v-for="(gender, i) of ['Male', 'Female', 'Unknown']">
          <div
            :key="i"
            class="mb-3"
            v-if="
            data && data.activity &&
              dataMutated[`Gender${gender}`] &&
              dataMutated[`Gender${gender}`].students.length > 0
            "
          >
            <div class="text-h6 mb-1">{{ gender }}</div>
            <TableTrend
              :data="dataMutated[`Gender${gender}`]"
              :showSubmission="false"
            ></TableTrend>
          </div>
        </template>
      </v-tab-item>
      <v-tab-item>
        <v-card  v-if="data && data.activity" class="ma-1">
          <v-simple-table dense>
            <thead>
              <tr>
                <th colspan="2">Platforms</th>
                <th>User</th>
                <th>Visit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">Both</td>
                <td>Total</td>
                <td>{{ platforms.uid.both }}</td>
                <td>{{ platforms.visit.both.total }}</td>
              </tr>
              <tr>
                <td>Full</td>
                <td>-</td>
                <td>{{ platforms.visit.both.full }}</td>
              </tr>
              <tr>
                <td>Lite</td>
                <td>-</td>
                <td>{{ platforms.visit.both.lite }}</td>
              </tr>
              <tr>
                <td colspan="2">Full</td>
                <td>{{ platforms.uid.full }}</td>
                <td>{{ platforms.visit.full }}</td>
              </tr>
              <tr>
                <td colspan="2">Lite</td>
                <td>{{ platforms.uid.lite }}</td>
                <td>{{ platforms.visit.lite }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <template v-for="(j, i) of ['Both', 'Full', 'Lite', 'Unknown']">
          <div
            :key="i"
            class="mb-3"
            v-if="
              dataMutated[`Platform${j}`] &&
              dataMutated[`Platform${j}`].students.length > 0
            "
          >
            <div class="text-h6 mb-1">{{ j }}</div>
            <TableTrend
              :data="dataMutated[`Platform${j}`]"
              :showSubmission="false"
            ></TableTrend>
          </div>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import TableTrend from "@/components/EventAnalysis/TableTrend";
import JsonExcel from "vue-json-excel";
import { mapState } from "vuex";
import FilterSchool from "./FilterSchool.vue";
export default {
  props: ["selectedEventCode", "schools"],
  components: {
    JsonExcel,
    TableTrend,
    FilterSchool,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    schoolId: null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: null,
    event: [],
    school: [],
    activity: [],
    isFetchActivity: false,
    dataMutated: {},
    days: [],
    festival: [],
    form: {
      schoolId: null,
      classroomIds: [],
      startDate: null,
      endDate: null,
    },
    //BOC:[platforms]
    platforms: {
      uid: {
        both: 0,
        full: 0,
        lite: 0,
      },
      visit: {
        both: {
          total: 0,
          full: 0,
          lite: 0,
        },
        full: 0,
        lite: 0,
      },
    },
    //EOC
    //
    tab: 0,
  }),
  created() {
    //BOC:[api]

    this.api.method = "post";
    if (!this.schoolId) {
      this.schoolId = this.schools.map((a) => a.School.id);
    }
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      if (this.isFetchActivity) {
        this.activity = resp;
        this.data.activity = resp;
        for (var st of this.data.students) {
          st.dsu = this.$_.filter(this.activity, (o) => {
            return o.studentId == st.id && o.action == "submission/add";
          });
          st.dau = this.$_.filter(this.activity, (o) => {
            return o.studentId == st.id;
          });
          this.processActivity(st);
        }

        this.filterActivity();
      } else {
        const { Checkpoint, ClassroomOnEvent, ...Event } = resp.Event;
        var student = [];
        var participant = [];
        var record = [];
        this.generateDays(Event);
        for (var c of ClassroomOnEvent) {
          c = c.Classroom;
          for (var s of c.Student) {
            for (var p of s.Participant) {
              participant.push(p);
              for (var r of p.Record) {
                record.push(r);
              }
            }
            s.Submission = this.$_.filter(resp.Submission, (o) => {
              return o.studentId == s.id;
            });
            this.processStudent(s, Checkpoint, resp.LabRecord);
            student.push(s);
          }
        }
        //
        this.data = {
          event: Event,
          checkpoints: Checkpoint,
          classrooms: ClassroomOnEvent,
          students: student,
          participants: participant,
          records: record,
          //  activty: resp.Activity,
          submissions: resp.Submission,
          labRecords: resp.LabRecord,
        };
        if (typeof this.schoolId == "number") {
          var school = this.$_.find(this.schools, { schoolId: this.schoolId });
          this.data.school = school.School;
          this.school.push(school.School);
        }
        this.event.push(Event);
        this.festival.push(Event.Festival);
        //
        this.mutateData(student);
        this.fetchActivity(ClassroomOnEvent);
      }
    };
    //EOC
    this.fetch();
  },
  mounted() {},
  methods: {
    selectSchool(id) {
      this.festival = [];
      this.event = [];
      this.school = [];
      this.isFetchActivity = false;
      if (id == 0) {
        this.schoolId = this.schools.map((a) => a.School.id);
      } else {
        this.schoolId = id;
      }
      this.fetch();
    },
    fetch() {
      this.api.url = `${this.$api.servers.log}/analysis/v1/festival`;
      this.api.params = {
        eventCode: this.selectedEventCode,
        schoolId: this.schoolId,
      };
      this.$api.fetch(this.api);
    },
    fetchActivity(Classroom) {
      this.form.schoolId = this.schoolId;
      this.isFetchActivity = true;
      this.form.startDate = this.days[0].timestamp;
      this.form.endDate = this.days[this.days.length - 1].timestamp;
      let result = Classroom.map((a) => a.Classroom.id);
      this.form.classroomIds = result;
      this.api.url = `${this.$api.servers.log}/log/v1/en/scoreboard/analysis/activity`;
      //this.api.params = this.$_.clone(this.form);
      this.api.params = {
        classroomIds: this.form.classroomIds,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
      }
      if(Array.isArray(this.form.schoolId)) {
        this.api.params.schoolIds = this.schoolId
      }
      else {
        this.api.params.schoolId = this.schoolId
      }
      this.$api.fetch(this.api);
    },
    filterActivity() {
      //BOC
      for (var day of this.days) {
        var filtered = this.$_.filter(this.activity, (o) => {
          return (
            this.$moment(day.timestamp)
              .add(1, "days")
              .diff(this.$moment(o.timestampCreated)) > 0
          );
        });
        //
        this.countActivity[day.i] = filtered.length;
      }
    },
    processActivity(student) {
      //BOC: check if active in activity
      if (student.dau.length < 1) {
        return;
      }
      student.milestones.dau = student.dau
        .map((item) => this.$moment(item.timestampCreated).format("YYYY/MM/DD"))
        .filter((value, index, self) => self.indexOf(value) === index);
      // EOC

      //BOC: check if active in submission
      if (student.dsu.length < 1) {
        return;
      }

      student.milestones.dsu = student.dsu
        .map((item) => this.$moment(item.timestampCreated).format("YYYY/MM/DD"))
        .filter((value, index, self) => self.indexOf(value) === index);
      //EOC
    },
    generateDays(event) {
      var duration =
        this.$moment(event.timestampEnd)
          .startOf("day")
          .diff(this.$moment(event.timestampStart).startOf("day"), "days") + 1;
      for (var i = 0; i < duration; i++) {
        this.days.push({
          i: i,
          timestamp: this.$moment(event.timestampStart)
            .startOf("day")
            .add(i, "days")
            .toISOString(),
        });
      }
    },
    mutateData(students) {
      //BOC
      var studentsGenderMale = this.$_.filter(students, (o) => {
        return o.gender == "Male";
      });
      this.dataMutated["GenderMale"] = {
        ...this.data,
        students: studentsGenderMale,
      };
      //EOC
      //BOC
      var studentsGenderFemale = this.$_.filter(students, (o) => {
        return o.gender == "Female";
      });
      this.dataMutated["GenderFemale"] = {
        ...this.data,
        students: studentsGenderFemale,
      };
      //EOC
      //BOC
      var studentsGenderUnknown = this.$_.filter(students, (o) => {
        return o.gender == null;
      });
      this.dataMutated["GenderUnknown"] = {
        ...this.data,
        students: studentsGenderUnknown,
      };
      //EOC
      //BOC
      var studentsPlatformBoth = this.$_.filter(students, (o) => {
        return o.platform == "both";
      });
      this.dataMutated["PlatformBoth"] = {
        ...this.data,
        students: studentsPlatformBoth,
      };
      //EOC
      //BOC
      var studentsPlatformFull = this.$_.filter(students, (o) => {
        return o.platform == "full";
      });
      this.dataMutated["PlatformFull"] = {
        ...this.data,
        students: studentsPlatformFull,
      };
      //EOC
      //BOC
      var studentsPlatformLite = this.$_.filter(students, (o) => {
        return o.platform == "lite";
      });
      this.dataMutated["PlatformLite"] = {
        ...this.data,
        students: studentsPlatformLite,
      };
      //EOC
      //BOC
      var studentsPlatformUnknown = this.$_.filter(students, (o) => {
        return !o.platform;
      });
      this.dataMutated["PlatformUnknown"] = {
        ...this.data,
        students: studentsPlatformUnknown,
      };
      //EOC
    },
    processStudent(student, checkpoints, labRecords) {
      student.platform = false;
      student.milestones = {};
      student.milestones.registered = student.timestampCreated;
      // if(!student.timestampActivated) {
      //   return
      // }
      student.milestones.activated = student.timestampActivated;
      //BOC: [platforms]
      var labRecord = this.$_.filter(labRecords, (o) => {
        return (
          o.model == "student" &&
          o.modelId == student.id &&
          o.labKey == "litevsfull"
        );
      })[0];
      if (labRecord) {
        student.LabRecord = labRecord;
        if (labRecord.a && labRecord.b) {
          this.platforms.uid.both += 1;
          this.platforms.visit.both.total += labRecord.a + labRecord.b;
          this.platforms.visit.both.full += labRecord.a;
          this.platforms.visit.both.lite += labRecord.b;
          student.platform = "both";
        } else if (labRecord.a) {
          this.platforms.uid.full += 1;
          this.platforms.visit.full += labRecord.a;
          student.platform = "full";
        } else if (labRecord.b) {
          this.platforms.uid.lite += 1;
          this.platforms.visit.lite += labRecord.b;
          student.platform = "lite";
        }
      }
      //EOC
      //BOC: check if joined
      if (student.Participant.length < 1) {
        return;
      }
      var participant = student.Participant[0];
      student.milestones.joined = participant.timestampCreated;
      //EOC
      //BOC: check if played
      if (student.Submission.length < 1) {
        return;
      }
      student.milestones.played = this.$_.minBy(student.Submission, (o) => {
        return o.timestampCreated;
      }).timestampCreated;
      //EOC
      //BOC
      for (var day of this.days) {
        var filtered = this.$_.filter(student.Submission, (o) => {
          return (
            this.$moment(day.timestamp)
              .add(1, "days")
              .diff(this.$moment(o.timestampCreated)) > 0
          );
        });
        //
        var star0 = 0;
        var star1 = 0;
        var star3 = 0;
        for (var checkpoint of checkpoints) {
          var filteredViaCheckpoint = this.$_.filter(filtered, (o) => {
            return o.questionKey == checkpoint.questionKey;
          });
          if (filteredViaCheckpoint.length < 1) break;
          var max = this.$_.maxBy(filteredViaCheckpoint, (o) => {
            return o.star;
          }).star;
          if (max >= 0) {
            star0 += 1;
          }
          if (max >= 1) {
            star1 += 1;
          }
          if (max >= 3) {
            star3 += 1;
          }
        }
        if (star0 == checkpoints.length && !student.milestones.tried) {
          student.milestones.tried = day.timestamp;
        }
        if (star1 == checkpoints.length && !student.milestones.completed) {
          student.milestones.completed = day.timestamp;
        }
        if (star3 == checkpoints.length && !student.milestones.mastered) {
          student.milestones.mastered = day.timestamp;
          break;
        }
      }
      //EOC
      return;
    },
  },
};
</script>