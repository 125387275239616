<template>
  <v-card class="mx-auto text-center" max-width="500">
    <transition name="fade">
      <div class="pa-3">
        <v-alert class="text-left" v-if="error" dense border="left" type="warning">
          {{ error }}
        </v-alert>
      </div>
    </transition>
    <v-card-title>
      <div class="text-center mx-auto">
        <v-icon class="mr-2" color="error">mdi-alert</v-icon>
        <span class="error--text">{{
          $t("view.PageMainFestivalRead.protection_failed_title")
        }}</span>
      </div>
    </v-card-title>
    <v-card-text
      >{{ $t("view.PageMainFestivalRead.protection_failed_description") }}
      <FormInput :callbackSelect="callbckSelect"></FormInput>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-large rounded @click="confirm()">
        {{ $t("action.confirm") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import FormInput from "@/components/Festival/SchoolCode/FormInput";
export default {
  components: {
    FormInput,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    error: null,
    formCode: "",
    code:null,
  }),
  created() {
    //
  },
  mounted() {
    this.code = this.$route.query.c
  },
  methods: {
    callbckSelect(code) {
      this.formCode = code;
    },
    confirm() {
      if (this.formCode && this.formCode !=this.code) {
       
          this.$router.push({
            name: "PageMainEventScoreboard",
            params: {
              eventCode: this.$route.params.eventCode,
            },
            query: { c: this.formCode },
          });
      } else {
        this.error = 'Group code is missing.';
      }
    },
  },
};
</script>