<template>
  <div class="pa-1">
    <div class="profile" tile v-if="school.logoUrl">
       <v-img width="200px" height="200" contain :src="school.logoUrl" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["school", "size"],
  data: () => ({
    color: [
      "000080",
      "008080",
      "800080",
      "008000",
      "808000",
      "800000",
      "808080",
      "0000FF",
      "00FFFF",
    ],
    randomColor: null,
  }),
  created() {
    //
  },
  mounted() {
    this.randomColor =
      this.color[Math.floor(Math.random() * this.color.length)];
  },
  methods: {
    //
  },
};
</script>
<style>

</style>