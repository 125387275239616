<template>
<v-container>
  <v-expand-transition>
    <v-responsive max-width="350">
      <div v-if="$moment(startDate) > $moment()">{{$t("view.PageMainEventScoreboard.countdown_start")}}</div>
      <div v-else-if=" $moment() >= $moment(startDate) && $moment() < $moment(endDate)">{{$t("view.PageMainEventScoreboard.countdown_end")}}</div>
      <div class="d-flex justify-space-between text-caption text-center">
         <div class="" v-if=" $moment() > $moment(endDate)"> {{$t("view.PageMainEventScoreboard.countdown_ended")}} {{$moment(endDate).format( "MMMM Do YYYY hh:mm A" )}}</div>
        <table v-else>
          <tr class="font-weight-medium text-h6">
            <td>{{ days }}</td>
            <td class="px-2">:</td>
            <td>{{ hours }}</td>
            <td class="px-2">:</td>
            <td>{{ minutes }}</td>
            <td class="px-2">:</td>
            <td>{{ seconds }}</td>
          </tr>
          <tr class="grey--text">
            <td>{{$t("view.PageMainEventScoreboard.countdown_time_days")}}</td>
            <td></td>
            <td>{{$t("view.PageMainEventScoreboard.countdown_time_hours")}}</td>
            <td></td>
            <td>{{$t("view.PageMainEventScoreboard.countdown_time_minutes")}}</td>
            <td></td>
            <td>{{$t("view.PageMainEventScoreboard.countdown_time_seconds")}}</td>
          </tr>
        </table>
      </div>
    </v-responsive>
  </v-expand-transition>
</v-container>
</template>

<script>
import moment from "moment";
export default {
  props: ["endDate", "startDate"],
  data: () => ({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    timer: null,
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  mounted() {
    this.showTime();
  },
  methods: {
    showTime() {
      this.timer = setInterval(() => {
        const now = new Date();
        var end = null;
        if (moment(this.startDate) > moment()) {
          end = moment(this.startDate).toDate();
        } else {
          end = moment(this.endDate).toDate();
        }
        const distance = end.getTime() - now.getTime();
        if (distance == 0) {
          clearInterval(this.timer);
          return;
        }
        const tdays = Math.floor(distance / this._days);
        const thours = Math.floor((distance % this._days) / this._hours);
        const tminutes = Math.floor((distance % this._hours) / this._minutes);
        const tseconds = Math.floor((distance % this._minutes) / this._seconds);
        this.minutes =
          Math.abs(tminutes) < 10
            ? "0" + Math.abs(tminutes)
            : Math.abs(tminutes);
        this.seconds =
          Math.abs(tseconds) < 10
            ? "0" + Math.abs(tseconds)
            : Math.abs(tseconds);
        this.hours =
          Math.abs(thours) < 10 ? "0" + Math.abs(thours) : Math.abs(thours);
        this.days =
          Math.abs(tdays) < 10 ? "0" + Math.abs(tdays) : Math.abs(tdays);
      }, 1000);
    },
  },
  destroyed(){
    clearInterval(this.timer);
  }
};
</script>

<style>
</style>