<template>
  <v-card-text>
    <ol class="list">
      <li>How can a student participate in this event?</li>
      <ul>
        <li>We use Hamochi website to host this event.</li>
        <li>The event starts from {{$moment(data.timestampStart).locale($_getLocale()).format("DD/MM/YYYY")}} to {{$moment(data.timestampEnd).locale($_getLocale()).format("DD/MM/YYYY")}}. Total {{$moment(data.timestampEnd).diff($moment(data.timestampStart),'days')+1}} days.</li>
        <li>During anyday of the event from morning 8am to night 11pm, the student may use computer or mobile device to <a @click="joinNow()" class="text-decoration-underline">click here to join the event</a>.
        </li>
        <li v-if="$route.query.s">School Code: {{$route.query.s}}</li>
      </ul>
      <li>How to use Hamochi solution?</li>
      <ul>
        <template v-if="data.Game && data.Game.code == 'DASH'">
          <li><a target="_blank" href="https://youtu.be/p3xUk3nY93E">Watch tutorial (Malay language).</a>.</li>
          <li>If internet is slow, you may use lite version. <a target="_blank" href="https://youtu.be/1oQCcB2MOpw">Watch lite version tutorial (Malay language)</a>.</li>
        </template>
        <li v-else><a target="_blank" href="/pdf/Hamochi Manual 2022 (EN).pdf">Click here to read the manual</a>.</li>
      </ul>
      <li>What's the purpose of this event?</li>
      <ul>
        <li>
          It is often necessary to reinforce the knowledge the students have just acquired. This event provides a fun interactive opportunity for them to do their revision.
        </li>
      </ul>
      <li>What makes this event special compared to other Maths contest?</li>
      <ul>
        <li>Students are encouraged to seek for the right solutions through various means during the event. They may find the answers from their textbooks or learn it from their guardians.
        </li>
        <li>If the students are unsastified with their own performance, they are encouraged to redo. Everytime they redo, the questions will be different. We encourage students to learn the right solution.
        </li>
      </ul>
      <li>Any preparations required before the event?</li>
      <ul>
        <li>Students are encouraged to revise before the event. <a @click="changeTab('topics')" class="text-decoration-underline">Click here to check the topics</a>.</li>
      </ul>
      <li>What's our role of as a guardian?</li>
      <ul>
        <li>We encourage guardian to involve in the event.</li>
        <li>Your role is very important as young student tends to learn from the surrouding adults.</li>
        <li>Before the start of the event, you may talk to the student about this event.</li>
        <li>At the beginning, you may sit beside the student to make sure he/she able to participate.</li>
        <li>During the event, you may encourage them to complete all the questions.</li>
        <li>If the student faced any difficulties in solving the questions, you are encouraged to teach and guide them.</li>
        <li>However, guardian shall not take away the student's device and solve the question directly.</li>
        <li>Remember that this is a learning opportunity for the student. They may fail in solving some questions. It's ok. Sometimes learning takes time. They may not able to solve it during this event but if we give them the opportunity to do it by themselves, they may able to solve it in near future.</li>
      </ul>
      <li>How are the scores being calculated?</li>
      <ul>
        <li v-if="data.Checkpoint">This event has {{data.Checkpoint.length}} topics.</li>
        <li>Student has to solve 10 questions per topics.</li>
        <li>One question is 80 points.</li>
        <li>The system will calculate the total score using student's best score for each topics.</li>
        <li>If the student is not satisfied with his own performance, he can redo the topics to get higher score.
        </li>
      </ul>
      <ul v-if="data.Game && data.Game.code == 'FISH'">
        <b>Following rules only applicable for this event game "Fishing Frenzy":</b>
        <li>Every time after answered a question, student will have the chance to play a mini game.</li>
        <li>Student can get maximum 20 points per mini game.</li>
        <li>There is a bonus mysterious fish for each topics. Student can get maximum 80 points for fishing it.</li>
      </ul>
      <ul v-else-if="data.Game && data.Game.code == 'DASH'">
        <b>Following rules only applicable for this event game "Bug Catcher":</b>
        <li>Player can catch butterflies in the game.</li>
        <li>1 point for each butterfly.</li>
        <li>Answering speed, game difficulty, mystery bug will affect the points slightly.</li>
      </ul>
      <li>Can the student redo the same topic?</li>
      <ul>
        <li>Yes, we encourage the student to redo if he wants.</li>
        <li>It helps the student to reinforce the acquired knowledge.</li>
        <li>To prevent the student from memorising the answers, every time when he redo any of the topic, our system will generate different set of questions.
        </li>
      </ul>
      <li>Why the questions are different every time?</li>
      <ul>
        <li>Our system will auto generate new questions when the student play the game.</li>
        <li>There are two main reasons to implement this system.</li>
        <li>First, to prevent the student from memorising the answers.</li>
        <li>Second, to prevent the student copy the answers from others.</li>
        <li>We encourage them to seek the right way when solving the mathematics questions.</li>
      </ul>
      <li>Can guardian check the student performance?</li>
      <ul>
        <li>
          Yes. <a @click="changeTab('students')" class="text-decoration-underline">Click here to check the latest ranking and performance</a>.
        </li>
      </ul>
      <li>Can't login or face any other technical issues?</li>
      <ul>
        <li>Some of the browsers may not be supported by default.</li>
        <li><router-link :to="{name:'PageMainPageTroubleshoot'}">Read the troubleshoot to find out the right solution</router-link>.</li>
        <!-- <li>If your device is more than 8 years, <a @click="joinLite()" target="_blank" class="text-decoration-underline">click here to use lite version</a>.</li> -->
        <li>Try other computer or mobile device if the issue persists.</li>
        <!-- <li>You may <a href="https://wa.me/+60123989864?text=Hi，I faced an issue in Hamochi." target="_blank">report to us</a> and we shall assist in fixing it.</li> -->
      </ul>
      <li>In-game question is incorrect?</li>
      <ul>
        <li>You may <a href="https://wa.me/+60123989864?text=Hi，I faced an issue in Hamochi." target="_blank">report to us</a> and we shall assist in fixing it.</li>
      </ul>
    </ol>
  </v-card-text>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
      'urlLite',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      changeTab(tab) {
        this.$emit('changeTab',tab)
      },
       joinNow() {
        this.$emit('joinNow')
      },
      joinLite() {
        this.$emit('joinLite')
      }
    }
  };
</script>
<style scoped>
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>