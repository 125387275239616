<template>
  <v-container>
    <v-data-table
      :headers="dessertHeaders"
      :items="topics"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="key"
      :items-per-page="100"
      hide-default-footer
      :no-data-text="$t('api.response.no_data')"
      :no-results-text="$t('api.response.no_data')"
    >
      <template v-slot:item.index="{ index }">
        <div>{{ index + 1 }}</div>
      </template>
      <template v-slot:item.chapter_name="{ item }">
        <div>
          {{ item.chapter_sort }}.
          {{ item.chapter_name }}
        </div>
      </template>
      <template v-slot:item.question_name="{ item }">
        <div>
          {{ item.name }}
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="
            selectedQuestion && selectedQuestion.question_code &&
            selectedQuestion.question_code.question &&
            selectedQuestion.question_code.key == item.key &&
            !api.isLoading
          "
          :colspan="headers.length"
        >
          <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-chip x-small class="ma-1">{{
              $t("string.sample_question")
            }}</v-chip>
          </div>
          <div
            style="
              -webkit-transform: scale(0.8);
              -moz-transform: scale(0.8);
              -ms-transform: scale(0.8);
              transform: scale(0.8);
            "
            v-html="selectedQuestion.question_code.question"
          ></div>
          <div class="text-right pa-2">
            <v-btn
              target="_blank"
              :to="{
                name: 'PageMainDiscoverSample',
                params: { chapterKey: item.chapter_key, code: item.key },
              }"
              class="view-question blue--text"
              small
              text
            >
              {{ $t("action.viewMore") }}</v-btn
            >
          </div>
        </td>
      </template>
      <template v-slot:item.expand="{ item }">
        <div class="text-right">
          <v-btn
            @click="show(item)"
            class="view-question text-right"
            color="primary"
            text
            :loading="api.isLoading && selectedQuestion.question_code.question.key == item.key"
          >
            {{ $t("action.viewSample") }}
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["checkpoint"],
  data() {
    return {
      items: [],
      question: [],
      selectedQuestion: {},
      selectedQuestionData: {},
      selectedItem: null,
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
      QuestionApi: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      questions: [],
      chapters: [],
      topics: [],
      data: [],
      id: null,
      item: null,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [],
    };
  },
  created() {
    this.dessertHeaders = [
      {
        text: this.$t("model.prop.no"),
        value: "index",
        sortable: false,
      },
      {
        text: this.$t("model.name.chapter"),
        align: "start",
        sortable: false,
        value: "chapter_name",
      },
      {
        text: this.$t("model.name.question"),
        sortable: false,
        value: "question_name",
      },
      { text: "", value: "expand" },
    ];

    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      var value = this.selectedItem;
      var question = this.items.find(function (entry) {
        return entry.key == value.key;
      });
      this.selectedQuestion["question"] = question.question_code.question;
    };
    //EOC
  },
  mounted() {
    //     var chapters = this.$_.map(this.checkpoint, "chapterKey");
    // this.chapters = this.$_.uniqBy(chapters,'chapterKey')
    this.chapters = [
      ...new Map(
        this.checkpoint.map((item) => [item["chapterKey"], item])
      ).values(),
    ];
    console.log(this.chapters);
    this.getQuestions();
  },
  methods: {
    show(value) {
      var question = this.expanded.find(function (entry) {
        return entry.key == value.key && entry.chapter_key == value.chapter_key;
      });
      this.expanded = [];
      this.selectedItem = value
      if (!question) {
        this.selectedQuestion = value;
        this.expanded.push(value);
       // this.fetchQuestion();
      } else if (
        question &&
        this.selectedItem.chapter_key != value.chapter_key &&
        this.selectedItem.key != value.key
      ) {
        this.expanded = [];
        this.selectedQuestion = value;
        this.selectedItem = value;
        this.expanded.push(value);
       // this.fetchQuestion();
      } else if (
        question &&
        this.selectedItem.chapter_key == value.chapter_key &&
        this.selectedItem.chapter_key == value.key
      ) {
        this.expanded = [];
      } else {
        this.selectedQuestion = value;
        this.selectedItem = value;
        this.expanded.push(value);
      //  this.fetchQuestion();
      }
    },
    fetchQuestion() {
      this.api.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        this.selectedItem.chapter_key +
        "/questions?lang=" +
        this.$_getLocale();
      this.$api.fetch(this.api);
    },

    async getQuestions() {
      for (let i = 0; i < this.chapters.length; i++) {
        await this.questionApiCall(this.chapters[i].chapterKey);
      }
    },
    async questionApiCall(chapterKey) {
      this.QuestionApi.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        chapterKey +
        "/questions";

      this.QuestionApi.callbackReset = () => {
        this.QuestionApi.isLoading = true;
        this.QuestionApi.isError = false;
      };

      this.QuestionApi.callbackError = (e) => {
        this.QuestionApi.isLoading = false;
        this.QuestionApi.isError = true;
        this.QuestionApi.error = e;
      };
      this.QuestionApi.callbackSuccess = (resp) => {
        this.checkpoint.forEach((q) => {
          let topic = resp.find((element) => element.key == q.questionKey);
          if (topic) {
            this.topics.push(topic);
          }
        });
        this.QuestionApi.isLoading = false;
      };
      this.QuestionApi.method = "GET";

      this.QuestionApi.params = { lang: this.$_getLocale() };
      await this.$api.fetch(this.QuestionApi);
    },
  },
  beforeMount() {},
};
</script>

<style>
.view-question::before {
  background-color: transparent !important;
}
</style>