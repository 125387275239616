var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.dessertHeaders,"items":_vm.topics,"expanded":_vm.expanded,"single-expand":true,"item-key":"key","items-per-page":100,"hide-default-footer":"","no-data-text":_vm.$t('api.response.no_data'),"no-results-text":_vm.$t('api.response.no_data')},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.chapter_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.chapter_sort)+". "+_vm._s(item.chapter_name)+" ")])]}},{key:"item.question_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(
          _vm.selectedQuestion && _vm.selectedQuestion.question_code &&
          _vm.selectedQuestion.question_code.question &&
          _vm.selectedQuestion.question_code.key == item.key &&
          !_vm.api.isLoading
        )?_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex my-3"},[_c('v-spacer'),_c('v-chip',{staticClass:"ma-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.$t("string.sample_question")))])],1),_c('div',{staticStyle:{"-webkit-transform":"scale(0.8)","-moz-transform":"scale(0.8)","-ms-transform":"scale(0.8)","transform":"scale(0.8)"},domProps:{"innerHTML":_vm._s(_vm.selectedQuestion.question_code.question)}}),_c('div',{staticClass:"text-right pa-2"},[_c('v-btn',{staticClass:"view-question blue--text",attrs:{"target":"_blank","to":{
              name: 'PageMainDiscoverSample',
              params: { chapterKey: item.chapter_key, code: item.key },
            },"small":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t("action.viewMore")))])],1)]):_vm._e()]}},{key:"item.expand",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"view-question text-right",attrs:{"color":"primary","text":"","loading":_vm.api.isLoading && _vm.selectedQuestion.question_code.question.key == item.key},on:{"click":function($event){return _vm.show(item)}}},[_vm._v(" "+_vm._s(_vm.$t("action.viewSample"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }