<template>
  <v-container>
    <v-row>
      <v-col cols="12" :sm="this.$route.query.c ? 12 : 6">
        <FilterStudent :callbackSelect="selectFilter" />
      </v-col>
      <v-col v-if="!this.$route.query.c" cols="12" sm="6">
        <FilterClassroom
          :classroom="classroom"
          :callbackSelect="selectClassroom"
        />
      </v-col>
    </v-row>
    <ScoreboardNews
      v-if="
        (data ? data : filterStudents).length > 0 &&
        $moment(eventStart).format('DD MM YYYY') !=
          $moment().format('DD MM YYYY')
      "
      :data="filterStudents"
      :gameCode="gameCode"
    ></ScoreboardNews>
    <div class="d-none d-lg-flex d-xl-flex text-right my-2">
      <v-spacer></v-spacer>
      <v-btn @click="printThis" :loading="loading" color="primary">
        {{ $t("action.download_image") }}
      </v-btn>
    </div>
    <div ref="printMe">
      <div>{{ $t("view.PageMainFestivalRead.tab_student_top3") }}</div>
      <TopStudent
        :students="topStudents"
        :checkpointLength="checkpointLength"
        :eventCode="eventCode"
      />
      <div class="mt-3">
        {{ $t("view.PageMainFestivalRead.tab_student_allStudent") }}
      </div>
      <div v-if="auth.token" class="text-right pb-2">
        <JsonExcel name="students.xls" worksheet="Hamochi" :data="downloadData">
        <v-btn color="primary" dark>
          {{ $t("action.download_excel") }}
        </v-btn>
      </JsonExcel>
      </div>
      <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12)">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("model.prop.no") }}</th>
              <th class="text-left">{{ $t("model.prop.fullname") }}</th>
              <th style="width: 50px"></th>
              <th v-if="auth.token" class="text-left">
                {{ $t("model.name.school") }}
              </th>
              <th class="text-left">{{ $t("model.name.classroom") }}</th>
              <th class="text-left">{{ $t("model.prop.status") }}</th>
              <th class="text-left">{{ $t("model.prop.totalAttempt") }}</th>
              <th class="text-left">{{ $t("model.prop.totalSubmission") }}</th>
              <th class="text-left">{{ $t("model.prop.progress") }}</th>
              <th class="text-left">{{ $t("model.prop.totalStar") }}</th>
              <th class="text-left">{{ $t("model.prop.totalScore") }}</th>
              <th class="text-left">{{ $t("model.prop.weakness") }}</th>
            </tr>
          </thead>
          <tbody class="table">
            <tr
              class="text-center"
              v-if="(data ? data : filterStudents).length == 0"
            >
              <td colspan="12" class="grey--text">
                {{ $t("api.response.no_data") }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in data ? data : filterStudents"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <router-link
                  class="text-decoration-none"
                  :to="{
                    name: 'PageMainFestivalScoreboardStudent',
                    params: {
                      studentId: item.Student.id,
                      eventCode: eventCode,
                      festivalCode: festivalCode,
                    },
                    query: { s: $route.query.s },
                  }"
                  >{{ item.name }}</router-link
                >
              </td>
              <td>
                <AAvatar
                  v-if="item.Student.avatar"
                  :avatar="item.Student.avatar"
                ></AAvatar>
                <div v-else>
                  <v-img
                    :max-height="40"
                    :max-width="40"
                    :src="require('@/assets/CS0040_default_F0.png')"
                  ></v-img>
                </div>
              </td>
              <td v-if="auth.token">{{ item.Student.School.name }}</td>
              <td>{{ item.Student.Classroom.name }}</td>
              <td>
                <ChipStudentStatus :data="item"></ChipStudentStatus>
              </td>
              <td v-if="item.totalTried"> {{item.totalTried}} </td>
              <td v-else>0</td>
              <td>{{ item.totalAttempt }}</td>
              <td v-if="item.progress">
                {{ item.progress }}/{{ checkpointLength }}
              </td>
              <td v-else>0/{{ checkpointLength }}</td>
              <td>{{ item.totalStar }} ★</td>
              <td>{{ item.totalScore }}</td>
              <td v-if="item.Record">
                <span v-if="item.Record[0]">
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'PageMainDiscoverQuiz',
                      params: { code: item.Record[0].Checkpoint.Question.key },
                    }"
                  >
                    {{
                      JSON.parse(
                        item.Record[0].Checkpoint.Question.translatableName
                      )[$_getLocale()]
                    }}</router-link
                  >
                </span>
                <span v-else>{{
                  $t("view.PageMainEventScoreboard.perfect_score")
                }}</span>
              </td>
              <td v-else>-</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>

<script>
import ChipStudentStatus from "@/components/Scoreboard/ChipStudentStatus";
import FilterStudent from "@/components/Scoreboard/FilterStudent";
import FilterClassroom from "@/components/Festival/FilterClassroom";
import ScoreboardNews from "@/components/Scoreboard/ScoreboardNews";
import TopStudent from "@/components/Festival/TopStudent.vue";
import VueHtml2Canvas from "vue-html2canvas";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
import { mapState } from "vuex";
export default {
  props: [
    "students",
    "classroom",
    "eventCode",
    "checkpointLength",
    "topStudents",
    "eventStart",
    "gameCode"
  ],
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    ChipStudentStatus,
    FilterStudent,
    FilterClassroom,
    ScoreboardNews,
    TopStudent,
    JsonExcel,
  },
  data: () => ({
    loading: false,
    output: null,
    downloadData: [],
    data: null,
    festivalCode: null,
    filterStudents: [],
    studentIds: [],
    filterBy: 2,
  }),
  methods: {
    async printThis() {
      this.loading = true;
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.downloadImg();
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "scoreboard.png";
      link.href = this.output;
      link.click();
      this.loading = false;
    },
    downloadExcel() {
      this.downloadData = [];
      var filterStudents =  this.data ? this.data : this.filterStudents
      for (var i = 0, l = filterStudents.length; i < l; i++) {
        this.downloadData.push({
          "#": i + 1,
          Name: filterStudents[i].name,
          School: filterStudents[i].Student.School.name,
          Classroom: filterStudents[i].Student.Classroom.name,
          Submission: filterStudents[i].totalAttempt,
          Progress:   filterStudents[i].progress ? JSON.stringify(filterStudents[i].progress +'/'+ this.checkpointLength) : JSON.stringify( '0/'+ this.checkpointLength),
          Stars: filterStudents[i].totalStar,
          Score: filterStudents[i].totalScore,
        });
      }
    },
    getProgress() {
      for (var s of this.filterStudents) {
        var p = 0;
        if (s.allRecord) {
          for (var r of s.allRecord) {
            if (r.topStar > 0) {
              p = p + 1;
            }
          }
          s.progress = p;
        }
      }
      this.downloadExcel();
    },
    selectFilter(filterBy) {
      this.filterBy = filterBy;
      if (this.filterBy == 1) {
        (this.data ? this.data : this.filterStudents).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else if (this.filterBy == 2) {
        (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));

        (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.status < b.status ? 1 : -1));
      }
      this.studentIds = [];
      for (
        var i = 0,
          l = this.data ? this.data.length : this.filterStudents.length;
        i < l;
        i++
      ) {
        this.studentIds.push(
          (this.data ? this.data[i] : this.filterStudents[i]).Student.id
        );
      }
      this.$store.commit("updateStudent", { studentIds: this.studentIds });
      this.getProgress();
    },
    selectClassroom(code) {
      this.data = null;
      if (code != "0000") {
        this.data = this.filterStudents.filter(
          (e) => e.Student.Classroom.code === code
        );
      }
      this.selectFilter(this.filterBy);
    },
  },
  created() {},
  mounted() {
    this.filterStudents = this.$_.cloneDeep(this.students);
    for (var i = 0, l = this.filterStudents.length; i < l; i++) {
      this.studentIds.push(this.filterStudents[i].Student.id);
      if (this.filterStudents[i].Record) {
        var allRecord = this.$_.cloneDeep(this.filterStudents[i].Record);
        this.filterStudents[i].allRecord = allRecord;
        for (var a = 0, b = this.filterStudents[i].Record.length; a < b; a++) {
          if (
            this.filterStudents[i].Record[a] &&
            this.filterStudents[i].Record[a].topStar == 3
          ) {
            this.filterStudents[i].Record.splice(a, 1);
            a--;
          }
        }
        if (this.filterStudents[i].Record.length == 0) {
          this.filterStudents[i].Record = true;
        } else {
          this.filterStudents[i].Record.sort((a, b) =>
            a.Checkpoint.Question.key > b.Checkpoint.Question.key ? 1 : -1
          );
        }
      }
    }
    this.festivalCode = this.$route.params.festivalCode;
    this.getProgress();
    if (this.$route.query.c) {
      this.selectClassroom(this.$route.query.c);
    }
  },
};
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  display: none !important;
}
.v-data-table__wrapper {
  -ms-overflow-style: none !important;
}
</style>