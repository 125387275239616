<template>
  <!-- <v-card class="mb-6"> -->
    <v-card-text class="pb-0">
      <div>
        <v-select
          @change="selectEvent($event)"
          dense
          outlined
          :label="$t('model.prop.schoolYear')"
          :items="Events"
          :item-text="name"
          v-model="selectedCode"
          item-value="code"
        ></v-select>
      </div>
      <div>
        <v-row v-if="selectedEvent">
          <v-col cols="12" sm="8">
            <div v-if="$_getLocale() == 'zh'">
              {{
                $moment(selectedEvent.timestampStart)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) A hh:mm")
              }}
              {{ $t("string.to") }}
              {{
                $moment(selectedEvent.timestampEnd)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) A hh:mm")
              }}
            </div>
            <div v-else>
              {{
                $moment(selectedEvent.timestampStart)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) hh:mm A")
              }}
              {{ $t("string.to") }}
              {{
                $moment(selectedEvent.timestampEnd)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) hh:mm A")
              }}
            </div>
            <div class="pt-3">
              <EventStatus :data="selectedEvent" />
            </div>
          </v-col>
          <v-col class="black--text" cols="12" sm="4">
            <Countdown
              :startDate="selectedEvent.timestampStart"
              :endDate="selectedEvent.timestampEnd"
            ></Countdown>
            <div v-if="schoolCode" class="text-right">
              <v-btn
                rounded
                color="success"
                x-large
                block
                @click="joinNow()"
              >{{$t('action.login_now')}}</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      
    </v-card-text>
  <!-- </v-card> -->
</template>

<script>
import { mapState } from "vuex";

import Countdown from "@/components/Scoreboard/Countdown";
import EventStatus from "@/components/Event/EventStatus";
import i18n from '@/plugins/i18n'
export default {
  components: {
    Countdown,
    EventStatus,
},
  computed: mapState({
    //
  }),
  props: ["Events", "code", "schoolCode" ,"callbackSelect", "callbackError","tab"],
  data: () => ({
    selectedEvent: null,
    selectedCode: null,
    lang: null,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$_getLocale() == "zh") {
      this.lang = this.$_getLocale() + "_cn";
    } else {
      this.lang = this.$_getLocale();
    }
    if (this.$route.query.e) {
      this.selectedCode = this.$route.query.e;
    } else {
      this.selectedCode = this.code;
    }
    this.selectEvent(this.selectedCode);
  },
  methods: {
    selectEvent(code) {
      this.selectedEvent = this.$_.find(this.Events, function (o) {
        return o.code == code;
      });
      if(this.$route.query.s){
        if(this.$route.query.c){
          if(this.$route.query.tab){
      window.history.pushState('','','?s='+this.$route.query.s+'&e='+ code + '&c='+this.$route.query.c + '&tab='+this.tab);
          }else{
      window.history.pushState('','','?s='+this.$route.query.s+'&e='+ code + '&c='+this.$route.query.c);
          }
        }else{
           if(this.$route.query.tab){
             window.history.pushState('','','?s='+this.$route.query.s+'&e='+ code + '&tab='+this.tab);
           }else{
             window.history.pushState('','','?s='+this.$route.query.s+'&e='+ code );
           }
        }
      }
      this.callbackSelect(code);
    },
    joinNow() {
      if(!(this.$moment() >= this.$moment(this.selectedEvent.timestampStart) && this.$moment() < this.$moment(this.selectedEvent.timestampEnd))){
      this.callbackError(this.selectedEvent.timestampStart);
      }else if(this.$moment() >= this.$moment(this.selectedEvent.timestampStart) && this.$moment() < this.$moment(this.selectedEvent.timestampEnd)){
        window.location.href = process.env.VUE_APP_URL_GAME+'?code='+this.schoolCode.School.code+'&lang='+this.$_getLocale();
      }
    },
    name: (item) => `${i18n.t('string.schoolYear_prefix')} ${item.schoolYear} ${i18n.t('string.schoolYear_suffix')}`,
  },
};
</script>