<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>
        <v-card-text>
          <div class="text-h4 text-center red--text">{{$t('view.PageMainFestivalRead.join_error_title')}}</div>
          <div class="pt-3 pb-6">
            <v-img
              class="ml-auto mr-auto"
              max-height="150"
              max-width="150"
              :src="require('@/assets/error.png')"
            ></v-img>
          </div>
          <div class="text-h6 text-center">
            <span>{{$t('view.PageMainFestivalRead.join_error_description')}}</span>
            <span>{{$moment(startDate).locale(lang).format("DD/MM/YYYY (dddd) hh:mm A")}}</span>
          </div>
          <div class="text-center pt-3">
            <v-btn @click="close" x-large rounded color="primary">{{$t('action.ok')}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["closeError", "startDate"],
  data: () => ({
    dialog: true,
    lang: null,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$_getLocale() == "zh") {
      this.lang = this.$_getLocale() + "_cn";
    } else {
      this.lang = this.$_getLocale();
    }
  },
  methods: {
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.closeError();
        this.dialog = true;
      }, 800);
    },
  },
};
</script>