<template>
  <v-container>
    <!-- BOC:[lang] -->
    <div class="text-right">
      <LangSelect />
    </div>
    <!-- EOC -->
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
     <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <JoinNow
      :closeError="close"
      :startDate="startDate"
      v-if="isJoinNowError"
      style="position: absolute"
    />
    <!-- EOC -->
    <v-card  v-if="selectedSchool || auth.token" class="mb-6">
      <div v-if="eventData.Game">
        <v-img
          v-if="eventData.Game.code == 'FISH'"
          max-height="350"
          max-width="100%"
          :src="require('@/assets/Festival/poster/FISH.png')"
        ></v-img>
        <v-img
          v-else-if="eventData.Game.code == 'DASH'"
          max-height="350"
          max-width="100%"
          :src="require('@/assets/Festival/poster/DASH.png')"
        ></v-img>
      </div>
      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="12" :sm="selectedSchool ? 8 : 12">
            <div v-if="Schools && Schools.length >1">
              <span class="pr-1" v-for="school in Schools" :key="school.code">
                <SchoolLogo :size="30" :school="school.School" />
              </span>
            </div>
            <div class="mt-2">
              <span class="text-h5 font-weight-black black--text">
                {{ data.name }} </span>
              <span class="text-h6 font-weight-medium">({{ data.subtitle }})</span>
            </div>
          </v-col>
          <v-col v-if="selectedSchool" cols="12" sm="4">
            <div class="d-flex">
              <v-spacer></v-spacer>
              <div>
                <v-img height="80" width="80" contain :src="selectedSchool.School.logoUrl"></v-img>
              </div>
            </div>  
            <div v-if="selectedSchool" class="text-right">
              <div class="text-caption">
                <span v-if="$_getLocale() != 'zh'">{{ $t("string.presented_by") }} </span>
                <span>Hamochi x {{ selectedSchool.School.name }}</span>
                <span v-if="$_getLocale() == 'zh'"> {{ $t("string.presented_by") }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="data.Event">
        <Event
          :callbackSelect="selectEvent"
          :callbackError="clickJoinNow"
          :code="data.Event[0].code"
          :schoolCode="selectedSchool"
          :Events="data.Event"
          :tab="tab"
        />
      </div>
    </v-card>
    <v-card id="content"  ref="content" v-if="selectedSchool || auth.token" class="mb-2">
      <v-card-text class="pb-0">
        <v-tabs @click="scrollToContent" show-arrows v-model="tab">
         <!-- <v-tab @click="changeTab(tab.value)" :value="tab.value" :href="'#'+tab.value" :key="tab.value" v-for="tab in tabs">
            {{ $t(tab.label) }}
          </v-tab> -->
          <v-tab @click="changeTab('guide')" value="guide" href="#guide">
            {{ $t("view.PageMainFestivalRead.tab_guide") }}
          </v-tab>
            <v-tab @click="changeTab('students')" value="students" href="#students">
            {{ $t("view.PageMainFestivalRead.tab_ranking") }}
          </v-tab>
           <v-tab @click="changeTab('topics')" value="topics" href="#topics">
            {{ $t("view.PageMainFestivalRead.tab_topic") }}
          </v-tab>
           <v-tab v-if="prizes && prizes.length !=0" @click="changeTab('prizes')" value="prizes" href="#prizes">
            {{ $t("model.name.prizes") }}
          </v-tab>
           <v-tab v-if="Schools && Schools.length >1" @click="changeTab('schools')" value="schools" href="#schools">
            {{ $t("model.name.schools") }}
          </v-tab>
           <v-tab v-if="Sponsors && Sponsors.length !=0" @click="changeTab('sponsors')" value="sponsors" href="#sponsors">
            {{ $t("model.name.sponsors") }}
          </v-tab>
          <v-tab v-if="auth.token" @click="changeTab('analysis')" value="analysis" href="#analysis">
            {{ $t("view.PageMainFestivalRead.analysis") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="guide">
            <Guide 
              :code="selectedSchoolCode"
              :data="eventData"
              @changeTab="changeTab"
              @joinNow="joinNow"
              @joinLite="joinLite"
            ></Guide>
          </v-tab-item>
          <v-tab-item value="students">
            <div v-if="!scoreboardApi.isLoading && selectedSchoolStudents && eventData.Game">
              <Scoreboard
                :students="selectedSchoolStudents"
                :topStudents="topStudents"
                :checkpointLength="checkpointLength"
                :classroom="classroom"
                :eventCode="selectedEventCode"
                :eventStart="eventData.timestampStart"
                :gameCode="eventData.Game.code"
              />
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
          <v-tab-item value="topics">
            <div v-if="!scoreboardApi.isLoading">
              <Checkpoint :checkpoint="eventData.Checkpoint" />
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
          <v-tab-item value="winners">
            <div v-if="!scoreboardApi.isLoading">
              <Winner
                :eventCode="selectedEventCode"
                :winner="eventData.Winner"
              />
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
          <v-tab-item v-if="prizes && prizes.length !=0" value="prizes">
            <div v-if="prizes">
              <!-- <Prize
                :classroom="eventData.ClassroomOnEvent"
                :prize="eventData.EventPrize"
                :Rewards="eventData.Rewards"
              /> -->
               <Prize
                :prizes="prizes"
              />
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
          <v-tab-item v-if="Schools && Schools.length >1" value="schools">
            <div>
              <School :schools="Schools" />
            </div>
          </v-tab-item>
          <v-tab-item v-if="Sponsors && Sponsors.length !=0" value="sponsors">
            <div>
              <Sponsor :sponsors="Sponsors" />
            </div>
          </v-tab-item>
          <v-tab-item v-if="selectedEventCode && auth.token" value="analysis">
            <div v-if="!scoreboardApi.isLoading">
              <Analysis :schools="Schools" :selectedEventCode="selectedEventCode" />
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <div class="mt-3" v-else>
      <v-row align="center" justify="center">
        <v-col>
          <div  v-if="!$route.query.s && !api.isLoading">
         <AlertProtection />
          </div>
        <div v-else-if="$route.query.s && !api.isLoading">
          <AlertProtectionFailed />
        </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import LangSelect from "@/components/Festival/LangSelect.vue";
import SchoolLogo from "@/components/Festival/SchoolLogo.vue";
import Event from "../../components/Festival/Event.vue";
import Scoreboard from "@/components/Festival/Scoreboard";
import Guide from "../../components/Festival/Guide.vue";
import Checkpoint from "@/components/Festival/Checkpoint";
import Winner from "@/components/Festival/Winner";
import Prize from "@/components/Festival/FestivalPrize";
import ALoader from "../../components/ALoader.vue";
import School from "../../components/Festival/School.vue";
import Sponsor from "../../components/Festival/Sponsor.vue";
import AlertProtection from "@/components/Festival/SchoolCode/AlertProtection";
import AlertProtectionFailed from "@/components/Festival/SchoolCode/AlertProtectionFailed";
import JoinNow from "../../components/Festival/Error/JoinNow.vue";

import i18n from "@/plugins/i18n";
import Analysis from "../../components/Festival/Analysis.vue";
export default {
  components: {
    LangSelect,
    SchoolLogo,
    Event,
    Scoreboard,
    Guide,
    Checkpoint,
    Winner,
    Prize,
    ALoader,
    Sponsor,
    School,
    AlertProtection,
    AlertProtectionFailed,
    JoinNow,
    Analysis
},
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    isJoinNowError: false,
    urlLite: process.env.VUE_APP_URL_LITE,
    startDate:null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    Schools: [],
    Sponsors: [],
    prizes:[],
    selectedSchool: null,
    selectedSchoolCode: null,
    selectedSchoolStudents: [],
    selectedEventCode: null,
    topStudents: [],
    participant: [],
    student: [],
    filterStudents: [],
    classroom: [],
    allClassroom: [],
    checkpointLength: 0,
    hamochiSchool: { logoUrl: "/images/Icon.png", name: "Hamochi School" },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    scoreboardApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    eventData: [],
    tab: null,
    tabs: [
      {
        value: 'guide',
        label: i18n.t("view.PageMainFestivalRead.tab_guide"),
      },

      {
        value: 'students',
        label: i18n.t("view.PageMainFestivalRead.tab_ranking"),
      },
      {
        value: 'topics',
        label: i18n.t("view.PageMainFestivalRead.tab_topic"),
      },
      // {
      //   value: 'winners',
      //   label: i18n.t("model.name.winners"),
      // },
      {
        value: 'prizes',
        label: i18n.t("model.name.prizes"),
      },
      {
        value: 'schools',
        label: i18n.t("model.name.schools"),
      },
      {
        value: 'sponsors',
        label: i18n.t("model.name.sponsors"),
      },
      // {
      //   key: 55,
      //   label: "Analysis",
      // },
      // {
      //   key: 56,
      //   label: "Follow Up",
      // },
      // {
      //   key:60,
      //   label:{
      //     en:'Winners',
      //     zh:'',
      //     ms:'',
      //   },
      // },
      // {
      //   key:80,
      //   label:{
      //     en:'Analysis',
      //     zh:'',
      //     ms:'',
      //   },
      // },
      // {
      //   key:90,
      //   label:{
      //     en:'Follow Up',
      //     zh:'',
      //     ms:'',
      //   },
      // },
    ],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainFestival"),
      to: { name: "PageMainFestival" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: this.$route.params.festivalCode,
      to: {
        name: "PageMainFestivalRead",
        params: { code: this.$route.params.festivalCode },
      },
      exact: false,
    });
    //BOC:[api]
    this.api.method = "get";
    this.scoreboardApi.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.scoreboardApi.callbackReset = () => {
      this.scoreboardApi.isLoading = true;
      this.scoreboardApi.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.scoreboardApi.callbackError = (e) => {
      this.scoreboardApi.isLoading = false;
      this.scoreboardApi.isError = true;
      this.scoreboardApi.error = e;
    };
    
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.Schools = this.data.FestivalSchool;
      this.Sponsors = this.data.FestivalSponsor;
      this.prizes = this.data.FestivalPrize;
      this.breadcrumbs.splice(-1,1);
      this.breadcrumbs.push({
        text: this.data.name,
        to: {
          name: "PageMainFestivalRead",
          params: { code: this.$route.params.festivalCode },
        },
        exact: false,
      });
      if (this.selectedSchoolCode) {
        this.selectSchool();
      }
    };
    this.scoreboardApi.callbackSuccess = (resp) => {
      this.allClassroom = [];
      this.classroom = [];
      this.filterStudents = [];

      window.scrollTo(0, 0);
      this.scoreboardApi.isLoading = false;
      this.eventData = resp;
      this.student = this.eventData.ClassroomOnEvent;
      this.participant = this.eventData.Participant;
      this.checkpointLength = this.eventData.Checkpoint.length;
      this.filter();
    };
    //EOC
  },

  mounted() {
    this.fetch();
    if (this.$route.query.s) {
      this.selectedSchoolCode = this.$route.query.s;
    }
     if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },

  methods: {
    changeTab(tab) {
      this.scrollToContent()
      this.tab = tab
        if(this.$route.query.s){
        if(this.$route.query.c){
      window.history.pushState('','','?s='+this.$route.query.s+'&e='+ this.eventData.code + '&c='+this.$route.query.c+ '&tab='+tab);
        }else
      window.history.pushState('','','?s='+this.$route.query.s+'&e='+ this.eventData.code +'&tab='+tab);
      }
      setTimeout(() => {
        this.scrollToContent()
      },100);
    },
    scrollToContent(){
      this.$refs.content.$el.scrollIntoView({ behavior: 'smooth' });
    },
    filter() {
      for (var i = 0, l = this.student.length; i < l; i++) {
        if (!this.auth.token) {
          if (
            this.selectedSchoolCode == this.student[i].Classroom.School.code
          ) {
            this.classroom.push({
              name: this.student[i].Classroom.name,
              id: this.student[i].Classroom.id,
              code: this.student[i].Classroom.code,
              schoolCode: this.student[i].Classroom.School.code,
            });
          }
        } else if (this.auth.token) {
          this.classroom.push({
            name: this.student[i].Classroom.name +'-'+this.student[i].Classroom.School.name,
            id: this.student[i].Classroom.id,
            code: this.student[i].Classroom.code,
            schoolCode: this.student[i].Classroom.School.code,
          });
        }
        for (
          var j = 0, k = this.student[i].Classroom.Student.length;
          j < k;
          j++
        ) {
          this.filterStudents.push({
            Student: {
              id: this.student[i].Classroom.Student[j].id,
              avatar: this.student[i].Classroom.Student[j].avatar,
              Classroom: {
                name: this.student[i].Classroom.Student[j].Classroom.name,
                id: this.student[i].Classroom.Student[j].Classroom.id,
                code: this.student[i].Classroom.Student[j].Classroom.code,
              },
              School: {
                name: this.student[i].Classroom.Student[j].School.name,
                id: this.student[i].Classroom.Student[j].School.id,
                code: this.student[i].Classroom.Student[j].School.code,
                logoUrl: this.student[i].Classroom.Student[j].School.logoUrl,
              },
            },
            name: this.student[i].Classroom.Student[j].name,
            totalStar: 0,
            totalAttempt: 0,
            totalScore: 0,
            participant: false,
            status: 0,
          });

          this.filterStudents.sort((a, b) => (a.name < b.name ? 1 : -1));
        }
      }
      
      for (var b = 0, m = this.participant.length; b < m; b++) {
        this.participant[b].Record.sort((a, b) =>
          a.topStar > b.topStar ? 1 : -1
        );
        for (var a = 0, n = this.filterStudents.length; a < n; a++) {
          if (
            this.participant[b].Student.id == this.filterStudents[a].Student.id
          ) {
            this.filterStudents.splice(a, 1);
            this.participant[b].status = 1;
            this.filterStudents.push(this.participant[b]);
          }
        }
      }

      this.filterStudents.sort((a, b) => (a.status < b.status ? 1 : -1));
      this.filterStudents
        .reverse()
        .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));

      this.filterStudentBySchool();
    },
    selectEvent(code) {
      this.selectedEventCode = code;
      this.api.isError = false;
      this.fetchEvent();
    },
    fetchEvent() {
      this.scoreboardApi.url =
        this.$api.servers.analysis +
        "/v1/" +
        this.$_getLocale() +
        "/event/" +
        this.selectedEventCode +
        "/scoreboard?pw=" +
        this.$route.query.pw;
      this.$api.fetch(this.scoreboardApi);
    },
    joinNow(){
 if(!(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd))){
      this.clickJoinNow(this.eventData.timestampStart);
      }else if(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd)){
        var url = process.env.VUE_APP_URL_GAME + '?lang='+this.$_getLocale();
        if(this.selectedSchoolCode) url += '&code='+this.selectedSchoolCode;
        window.location.href = url;
      }
    },
    joinLite(){
      // if(!(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd))){
      // this.clickJoinNow(this.eventData.timestampStart);
      // }else if(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd)){
      //   this.urlLite = `${this.urlLite}${this.$_getLocale()}/home?code=${this.selectedSchoolCode}`
      //   window.open(this.urlLite, '_blank');
      // }
    },
    clickJoinNow(date) {
      this.startDate = date
      this.isJoinNowError = true;
    },
    close() {
      this.isJoinNowError = false;
    },
    filterStudentBySchool() {
      var code = this.selectedSchoolCode;
      if (!this.auth.token) {
        var filterStudents = this.$_.cloneDeep(this.filterStudents);
        this.selectedSchoolStudents = this.$_.filter(filterStudents, (o) => {
          return o.Student.School.code == code;
        });
      } else if (this.auth.token) {
        this.selectedSchoolStudents = this.filterStudents;
      }
      var topStudents = this.$_.take(this.filterStudents, 3);
      this.topStudents = this.$_.filter(topStudents, (o) => {
        return o.totalScore > 0;
      });
    },
   
    fetch() {
      this.api.url =
        this.$api.servers.classroom +
        "/v1/" +
        this.$_getLocale() +
        "/festival/" +
        this.$route.params.festivalCode;
      this.$api.fetch(this.api);
    },
    selectSchool() {
      var code = this.selectedSchoolCode;
      this.selectedSchool = this.$_.find(this.Schools, function (o) {
        return o.School.code == code;
      });
    },
    name: (item) => `${item.name}`,
  },
};
</script>
<style>
#content{
  scroll-margin: 60px !important;
}
</style>