<template>
  <v-card flat>
    <v-card-title>{{ $t("view.PageMainPage.220901.sponsors") }}</v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-around flex-wrap">
        <div v-for="(sponsor, i) in sponsors.Event" :key="i" >
        <v-card outlined class="text-center my-5">
          <SponsorLogo :size="200" v-if="sponsor.Sponsor.logoUrl" :school="sponsor.Sponsor" />
          <div class="pa-2">
            <a
              v-if="sponsor.Sponsor.websiteUrl"
              class="text-decoration-none"
              :href="sponsor.Sponsor.websiteUrl"
              target="_blank"
              >{{ sponsor.Sponsor.name }}</a
            >
            <span class="black--text" v-else>{{
              sponsor.Sponsor.name
            }}</span>
          </div>
        </v-card>
      </div>
      </div>
      <div class="d-flex justify-space-around flex-wrap">
        <div v-for="(sponsor, i) in sponsors.Series" :key="i" >
        <v-card outlined class="text-center my-5">
          <SponsorLogo :size="200" v-if="sponsor.Sponsor.logoUrl" :school="sponsor.Sponsor" />
          <div class="pa-2">
            <a
              v-if="sponsor.Sponsor.websiteUrl"
              class="text-decoration-none"
              :href="sponsor.Sponsor.websiteUrl"
              target="_blank"
              >{{ sponsor.Sponsor.name }}</a
            >
            <span class="black--text" v-else>{{
              sponsor.Sponsor.name
            }}</span>
          </div>
        </v-card>
      </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SponsorLogo from "@/components/Festival/SponsorLogo.vue";
export default {
  components: {
    SponsorLogo,
  },
  computed: mapState({
    //
  }),
  props: ["sponsors"],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>