<template>
  <v-card-text>
    <ol class="list">
      <li>Bagaimanakah untuk pelajar menyertai pertandingan ini?</li>
      <ul>
        <li>Kami menggunakan laman sesawang Hamochi untuk menguruskan pertandingan ini.</li>
        <li>Pertandingan ini bermula dari {{$moment(data.timestampStart).locale($_getLocale()).format("DD/MM/YYYY")}} sehingga {{$moment(data.timestampEnd).locale($_getLocale()).format("DD/MM/YYYY")}}.{{$moment(data.timestampEnd).diff($moment(data.timestampStart),'days')+1}} hari keseluruhan</li>
        <li>Pada hari pertandingan dari 8.00 pagi sehingga 11.00 malam, para pelajar boleh menggunakan komputer atau telefon pintar. <a @click="joinNow()" class="text-decoration-underline">Klik di sini untuk menyertai pertandingan.</a>
        </li>
        <li v-if="$route.query.s">Kod Sekolah: {{$route.query.s}}</li>
      </ul>
      <li>Apakah cara mengguna solusi Hamochi?</li>
      <ul>
        <template v-if="data.Game && data.Game.code == 'DASH'">
          <li><a target="_blank" href="https://youtu.be/p3xUk3nY93E">Tengok tutorial video untuk versi penuh</a>.</li>
          <li>Jika kelajuan internet perlahan, boleh menggunakan versi ringgan. <a target="_blank" href="https://youtu.be/1oQCcB2MOpw">Tengok tutorial video untuk versi ringgan</a>.</li>
        </template>
        <li v-else><a target="_blank" href="/pdf/Hamochi Manual 2022 (MS).pdf">Klik di sini untuk menbaca manual</a>.</li>
      </ul>
      <li>Apakah tujuan pertandingan ini?</li>
      <ul>
        <li>
          Ia penting untuk mengukuhkan pengetahuan yang baru dalam kalangan pelajar. Pertandingan ini menyediakan peluang untuk mereka mengulang kaji dengan  lebih menarik.
        </li>
      </ul>
      <li>Apakah yang membuatkan pertandingan ini unik dari pertandingan matematik lain?</li>
      <ul>
        <li>Ia menggalakkan pelajar untuk mencari pelbagai cara untuk mendapatkan penyelesaian yang betul ketika pertandingan. Mereka mungkin akan mendapat jawapan dari buku teks ataupun belajar dari penjaga masing-masing.
        </li>
        <li>Jika pelajar tidak berpuas hati dengan prestasi mereka, mereka boleh menjawab semula. Setiap kali mereka menjawab semula, soalan akan berubah. Kami menggalakkan pelajar untuk mencari jawapan yang betul.
        </li>
      </ul>
      <li>Apakah persiapan yang perlu dilakukan sebelum pertandingan?</li>
      <ul>
        <li>Peljar digalakkan untuk mengulang kaji sebelum hari pertandingan.<a @click="changeTab('topics')" class="text-decoration-underline">Klik di sini untuk menyemak topik</a>.</li>
      </ul>
      <li>Apakah peranan penjaga?</li>
      <ul>
        <li>Kami menggalakkan penjaga untuk memantau ketika pertandingan berlangsung.</li>
        <li>Peranan penjaga amat penting kerana anak muda belajar berdasarkan orang dewasa disekelilingnya.</li>
        <li>Sebelum pertandingan bermula, penjaga boleh memberitahu pelajar berkenaan pertandingan ini.</li>
        <li>Pada permulaan pertandingan, penjaga boleh memantau pelajar supaya mereka dapat menyertai pertandingan.</li>
        <li>Ketika pertandingan, penjaga boleh menggalakkan pelajar untuk menyiapkan semua soalan.</li>
        <li>Jika pelajar menghadapi kesukaran dalam menjawab soalan, penjaga digalakkan untuk mengajar dan memberi petunjuk kepada mereka.</li>
        <li>Walaubagaimanapun, penjaga tidak boleh mengambil peranti pelajar dan selesaikan soalan.</li>
        <li>Ingatlah ini adalah peluang untuk pelajar belajar. Mereka mungkin gagal untuk menjawab beberapa soalan. Tidak mengapa kerana ia adalah proses pembelajaran. Mereka mungkin tidak dapat menjawab sekarang dan berilah mereka peluang untuk menjawab dengan sendiri, mungkin pada masa akan datang mereka akan dapat menjawabnya.</li>
      </ul>
      <li>Bagaimanakah markah dikira?</li>
      <ul>
        <li v-if="data.Checkpoint">Pertandingan ini mengandungi {{data.Checkpoint.length}} topik.</li>
        <li>Pelajar hendaklah menyelesaikan 10 soalan setiap topik.</li>
        <li>Satu soalan mempunyai 80 markah.</li>
        <li>Sistem ini akan mengira jumlah markah berdasarkan markah terbaik pelajar bagi setiap topik.</li>
        <li>Jika pelajar tidak berpuas hati dengan prestasi mereka, mereka boleh menjawab semula topik tersebut untuk mendapatkan markah yang tinggi.</li>
      </ul>
      <ul v-if="data.Game && data.Game.code == 'FISH'">
        <b>Peraturan untuk acara kali ini "Fishing Frenzy":</b>
        <li>Setiap kali mereka menjawab soalan, mereka akan berpeluang untuk bermain permainan mini.</li>
        <li>Pelajar boleh mendapat maksimum 20 markah setiap permainan mini.</li>
        <li>Ia mempunyai bonus ikan misteri untuk setiap topik. Pelajar boleh mendapat maksimum 80 markah jika menangkapnya.</li>
      </ul>
      <ul v-else-if="data.Game && data.Game.code == 'DASH'">
        <b>Peraturan untuk acara kali ini "Bug Catcher":</b>
        <li>Pelajar boleh menangkap rama-rama dalam permainan.</li>
        <li>1 markah untuk setiap rama-rama.</li>
        <li>Kelajuan menjawab, kesukaran, serangga misteri ada sikit kesan terhadap markah.</li>
      </ul>
      <li>Adakah pelajar boleh menjawab semula topik yang sama?</li>
      <ul>
        <li>Yes, we encourage the student to redo if he wants.</li>
        <li>Boleh, kami menggalakkan untuk pelajar jawab semula jika mereka mahu.</li>
        <li>Ia membantu pelajar untuk menguatkan pengetahuan yang diperlukan.</li>
        <li>Untuk mengelakkan pelajar menghafal jawapan, setiap kali mereka menjawab topik semula, sistem kami akan mengeluarkan soalan yang berlainan.</li>
      </ul>
      <li>Kenapa soalan berlainan pada setiap masa?</li>
      <ul>
        <li>Sistem kami mengeluarkan soalan baru secara automatik apabila mereka bermain.</li>
        <li>Ia ada dua sebab yang penting untuk melaksanakan sistem ini.</li>
        <li>Pertama, ia dapat mengelakkan pelajar untuk menghafal jawapan.</li>
        <li>Kedua, untuk mengelakkan pelajar meniru jawapan dari orang lain.</li>
        <li>Kami menggalakkan mereka untuk mencari solusi yang betul ketika menjawab soalan matematik.</li>
      </ul>
      <li>Adakah penjaga boleh semak prestasi pelajar?</li>
      <ul>
        <li>
          Boleh. <a @click="changeTab('students')" class="text-decoration-underline">Klik di sini untuk menyemak prestasi dan kedudukan terkini.</a>.
        </li>
      </ul>
      <li>Tidak boleh log masuk ataupun hadapi masalah teknikal?</li>
      <ul>
        <li>Tidak semua pelayar sokong laman sesawang kami.</li>
        <li><router-link :to="{name:'PageMainPageTroubleshoot'}">Baca cara penyelesaian masalah untuk mencari jalan penyelesaian</router-link>.</li>
        <!-- <li>Jika peranti anda lebih dari 8 tahun, <a @click="joinLite()" target="_blank" class="text-decoration-underline">klik di sini untuk menggunakan versi lite</a>.</li> -->
        <li>Cuba menggunakan komputer atau telefon pintar lain jika masalah tidak selesai.</li>
        <!-- <li>Anda boleh <a href="https://wa.me/+60123989864?text=Hi, saya terjumpa isu dalam Hamochi." target="_blank">menghubungi kami</a></li> -->
      </ul>
      <li>Jawapan permainan salah?</li>
      <ul>
        <li>Sila <a href="https://wa.me/+60123989864?text=Hi, saya terjumpa isu dalam Hamochi." target="_blank">menghubungi kami</a>.</li>
      </ul>
    </ol>
  </v-card-text>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
      'urlLite',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      changeTab(tab) {
        this.$emit('changeTab',tab)
      },
       joinNow() {
        this.$emit('joinNow')
      },
      joinLite() {
        this.$emit('joinLite')
      }
    }
  };
</script>
<style scoped>
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>