<template>
    <div class="mb-10">
      <v-simple-table style="border: 1px solid rgba(0,0,0,0.12);">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("model.prop.no") }}</th>
              <th class="text-left">{{ $t("model.prop.fullname") }}</th>
              <th v-if="auth.token" class="text-left">{{ $t("model.name.school") }}</th>
              <th class="text-left"></th>
              <th class="text-left">{{ $t("model.prop.totalSubmission") }}</th>
              <th class="text-left">{{ $t("model.prop.progress") }}</th>
              <th class="text-left">{{ $t("model.prop.totalStar") }}</th>
              <th class="text-left">{{ $t("model.prop.totalScore") }}</th>
            </tr>
          </thead>
          <tbody class="table">
            <tr class="text-center" v-if="filterStudents.length == 0">
              <td colspan="10" class="grey--text">
                {{ $t("api.response.no_data") }}
              </td>
            </tr>
            <tr v-for="(item, index) in filterStudents" :key="index">
              <td>{{ index + 1 }}</td>
              <td v-if="selectedSchool == item.Student.School.code || auth.token">{{ item.name }}</td>
               <td v-else>Someone from {{ item.Student.School.name }}</td>
               <td v-if="auth.token">{{ item.Student.School.name }}</td>
               <td>
                <AAvatar
                  v-if="item.Student.avatar"
                  :avatar="item.Student.avatar"
                ></AAvatar>
              </td>
              <td>{{ item.totalAttempt }}</td>
              <td v-if="item.progress">
                {{ item.progress }}/{{ checkpointLength }}
              </td>
              <td v-else>0/{{ checkpointLength }}</td>
              <td>{{ item.totalStar }} ★</td>
              <td>{{ item.totalScore }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
</template>

<script>
 import { mapState } from 'vuex'
export default {
  props: ["students", "eventCode", "checkpointLength"],
  components: {
  },
   computed: mapState({
      auth: state => state.auth.data,
    }),
  data: () => ({
    loading: false,
    output: null,
    data: null,
    filterStudents: [],
    studentIds: [],
    filterBy: 2,
    selectedSchool:null,
  }),
  methods: {
    getProgress() {
      for (var s of this.filterStudents) {
        var p = 0;
        if (s.allRecord) {
          for (var r of s.allRecord) {
            if (r.topStar > 0) {
              p = p + 1;
            }
          }
          s.progress = p;
        }
      }
    },
  },
  created() {},
  mounted() {
    this.selectedSchool = this.$route.query.s
    this.filterStudents = this.$_.cloneDeep(this.students);
    for (var i = 0, l = this.filterStudents.length; i < l; i++) {
      this.studentIds.push(this.filterStudents[i].Student.id);
      if (this.filterStudents[i].Record) {
        var allRecord = this.$_.cloneDeep(this.filterStudents[i].Record);
        this.filterStudents[i].allRecord = allRecord;
        for (var a = 0, b = this.filterStudents[i].Record.length; a < b; a++) {
          if (
            this.filterStudents[i].Record[a] &&
            this.filterStudents[i].Record[a].topStar == 3
          ) {
            this.filterStudents[i].Record.splice(a, 1);
            a--;
          }
        }
        if (this.filterStudents[i].Record.length == 0) {
          this.filterStudents[i].Record = true;
        } else {
          this.filterStudents[i].Record.sort((a, b) =>
            a.Checkpoint.Question.key > b.Checkpoint.Question.key ? 1 : -1
          );
        }
      }
    }
    this.getProgress();
  },
};
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  display: none !important;
}
.v-data-table__wrapper {
  -ms-overflow-style: none !important;
}
</style>