<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.school')"
        :placeholder="$t('model.name.school')"
        class="white"
        :items="data"
        item-text="name"
        outlined
        v-model="item"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect", "schools"],
  data: () => ({
    code: null,
    data:[],
    item: {
      id: 0,
      code:'0000',
      name: "All",
      logoUrl:null
    },
  }),
  watch: {
    item: function (val) {
      this.callbackSelect(val);
    },
  },
  created() {
    this.data = this.schools.map((a) => a.School);
   
   
    this.data.push({
      id: 0,
      code:'0000',
      name: this.$t("model.filterBy.all"),
      logoUrl:null
    });
    this.data.sort((a, b) => (a.id > b.id ? 1 : -1));
    // this.classroom.reverse();
    // if (this.$route.query.c) {
    //   this.item.code = this.$route.query.c;
    // }
  },
};
</script>

<style>
</style>