<template>
  <v-card>
    <v-card-title>{{ $t("model.name.schools") }}</v-card-title>
    <v-card-text>
      <ol>
        <li class="mb-3" v-for="school in schools" :key="school.id">
        <SchoolLogo :size="46" :school="school.School" />
          <span class="ml-3 black--text" >{{school.School.name}}</span>
        </li>
      </ol>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SchoolLogo from "@/components/Festival/SchoolLogo.vue";
export default {
  components: {
    SchoolLogo,
  },
  computed: mapState({
    //
  }),
  props: ["schools"],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>