<template>
<div v-if="data">
  <v-chip v-if="data.participant == false || !data.Record" small dark color="red">
    {{$t("model.value.status.absent")}}
  </v-chip>
  <v-chip v-else small dark color="green">
    {{$t("model.value.status.joined")}}
  </v-chip>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      "data",
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>