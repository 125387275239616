<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.classroom')"
        :placeholder="$t('model.name.classroom')"
        class="white"
        :items="classroom"
        item-text="name"
        outlined
        v-model="item"
        item-value="code"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect", "classroom"],
  data: () => ({
    code: null,
    item: {
      id: 0,
      code:'0000',
      name: "All",
    },
  }),
  watch: {
    item: function (val) {
      this.callbackSelect(val);
    },
  },
  created() {
    this.classroom.sort((a, b) => (a.name < b.name ? 1 : -1));
    this.classroom.push({
      id: 0,
      code:'0000',
      name: this.$t("model.filterBy.all"),
    });
    this.classroom.reverse();
    if (this.$route.query.c) {
      this.item.code = this.$route.query.c;
    }
  },
};
</script>

<style>
</style>