<template>
  <div v-if="data">
    <div v-if="videoUrl" class="ma-3" style="max-width:500px;">
      <div class="responsive-google-slides">
        <iframe :src="'https://www.youtube.com/embed/'+videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <GuideZh
      v-if="$_getLocale() == 'zh'"
      :data="data"
      :urlLite="urlLite"
      @changeTab="changeTab"
      @joinNow="joinNow"
      @joinLite="joinLite"
    ></GuideZh>
    <GuideMs
      v-else-if="$_getLocale() == 'ms'"
      :data="data"
      :urlLite="urlLite"
      @changeTab="changeTab"
      @joinNow="joinNow"
       @joinLite="joinLite"
    ></GuideMs>
    <GuideEn
      v-else
      :data="data"
      :urlLite="urlLite"
      @changeTab="changeTab"
      @joinNow="joinNow"
      @joinLite="joinLite"
    ></GuideEn>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import GuideEn from "@/components/Festival/GuideEn.vue";
  import GuideMs from "@/components/Festival/GuideMs.vue";
  import GuideZh from "@/components/Festival/GuideZh.vue";
  export default {
    components:{
      GuideEn,
      GuideMs,
      GuideZh,
    },
    computed: mapState({
      //
    }),
    props:[
      'code',
      'data',
    ],
    data: () => ({
      urlGame: process.env.VUE_APP_URL_GAME,
      urlLite: process.env.VUE_APP_URL_LITE,
      videoUrl: null,
    }),
    created() {
      this.urlGame += '?code=' + this.code
      //BOC
      this.urlLite = `${this.urlLite}${this.$_getLocale()}/home?code=${this.$route.query.s}`
      //EOC
    },
    mounted() {
      //
    },
    watch: { 
      data: function() {
        this.loadVideo()
      }
    },
    methods: {
      changeTab(tab) {
        this.$emit('changeTab',tab)
      },
      joinNow() {
        this.$emit('joinNow')
      },
      joinLite() {
        this.$emit('joinLite')
      },
      loadVideo() {
        //BOC:[video]
        switch(this.data.Game.code) {
          case 'DASH':
            switch(this.$_getLocale()) {
              case 'zh':
                this.videoUrl = "t3dLSJ_OcfQ"
                break
              case 'ms':
                this.videoUrl = "dD2wqLyTeKA"
                break
              default:
                this.videoUrl = "zxPtKEuxeUE"
            }
            break;
          case 'FISH':
            switch(this.$_getLocale()) {
              case 'zh':
                this.videoUrl = "VAKYg6wWt28"
                break
              case 'ms':
                this.videoUrl = "sHCtl1_bkZI"
                break
              default:
                this.videoUrl = "8fSo6pSeFPU"
            }
            break;
        }
        //EOC
      }
    }
  };
</script>
<style scoped>
  /* BOC: iframe */
  .responsive-google-slides {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Ratio */
    height: 0;
    overflow: hidden;
  }
  .responsive-google-slides iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  /* EOC */
</style>