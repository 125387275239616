<template>
  <v-container>
    <v-simple-table fixed-header height="520px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{$t("model.prop.no")}}</th>
            <th class="text-left">{{$t("model.name.prize")}}</th>
            <th class="text-left">{{$t("model.name.classroom")}}</th>
            <th class="text-left">{{$t("model.name.student")}}</th>
            <th class="text-left">{{$t("model.prop.totalScore")}}</th>
          </tr>
        </thead>
        <tbody >
          <tr class="text-center"  v-if="winner.length == 0">
            <td colspan="5" class="grey--text">{{$t("api.response.no_data")}}</td>
          </tr>
          <tr v-for="(item, index) in winner" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{
                JSON.parse(item.GamePrize.PrizeType.translatableName)[
                  $_getLocale()
                ]
              }}
            </td>
            <td>{{ item.Participant.Student.Classroom.name }}</td>
             <td v-if="selectedSchool == item.Participant.Student.School.code || auth.token" >
                <router-link
                class="text-decoration-none"
                :to="{
                  name: 'PageMainEventScoreboardStudent',
                  params: { studentId: item.Participant.Student.id, eventCode: eventCode },
                  query: { pw: $route.query.pw },
                }"
                >{{ item.Participant.Student.name }}</router-link
              >
             </td>
             <td v-else>Someone from {{item.Participant.Student.School.name}}</td>
             <td>{{ item.Participant.totalScore }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
 import { mapState } from 'vuex'
export default {
props:["winner","eventCode"],
 data: () => ({
    selectedSchool:null,
  }),
   computed: mapState({
      auth: state => state.auth.data,
    }),
beforeMount(){
  this.selectedSchool = this.$route.query.s
  this.winner.sort((a, b) => (a.GamePrize.PrizeType.key > b.GamePrize.PrizeType.key) ? 1 : -1);
   this.winner.sort((a, b) => (a.Participant.name > b.Participant.name) ? 1 : -1);
   this.winner.sort((a, b) => (a.Participant.Student.Classroom.name < b.Participant.Student.Classroom.name) ? 1 : -1);
   this.winner.sort((a, b) => (a.GamePrize.PrizeType.sort > b.GamePrize.PrizeType.sort) ? 1 : -1);  
      
}
}
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
   display: none;
}
.v-data-table__wrapper {
    -ms-overflow-style: none;
}
</style>