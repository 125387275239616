<template>
  <v-container>
    <v-simple-table fixed-header height="450px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("model.prop.no") }}</th>
            <th class="text-left">{{ $t("model.name.prize") }}</th>
            <th class="text-left">{{ $t("model.prop.itemName") }}</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody v-if="prizes && prizes.length == 0">
          <tr class="text-center">
            <td colspan="4" class="grey--text">
              {{ $t("api.response.no_data") }}
            </td>
          </tr>
        </tbody>
        <tbody v-if="prizes && prizes.length != 0">
          <tr v-for="(item, index) in prizes" :key="index">
            <td>{{ index + 1 }}</td>
            <td v-if="item.prizeName.includes('{')">
              {{ JSON.parse(item.prizeName)[$_getLocale()] }} <span v-if="item.amount">x {{item.amount}} </span>
            </td>
            <td v-else>
              {{ item.prizeName }} <span v-if="item.amount"> x {{item.amount}} </span>
            </td>
            <td v-if="item.itemName.includes('{')">
              {{ JSON.parse(item.itemName)[$_getLocale()] }}
            </td>
            <td v-else>
              {{ item.itemName }}
            </td>
             <td>
              <div>
                <v-img
                  v-if="item.imageUrl"
                  max-height="100"
                  max-width="100"
                  :src="item.imageUrl"
                ></v-img>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  props: ["prizes"],
  data: () => ({}),
  mounted() {},
};
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-data-table__wrapper {
  -ms-overflow-style: none;
}
</style>