<template>
  <div style="max-width: 800px;" class="mx-auto" >
    <span v-for="lang in langs" :key="lang.value">
      <v-btn :color="value == lang.value ?'blue':null" @click="changeLang(lang.value)" small text>{{ lang.name }}</v-btn>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    langs: [
      {
        name: "Bahasa Melayu",
        value: "ms",
      },
      {
        name: "中文",
        value: "zh",
      },
      {
        name: "English",
        value: "en",
      },
    ],
    value: null,
  }),
  created() {
    //
  },
  mounted() {
    this.value = this.$_getLocale();
  },
  methods: {
    changeLang(val) {
      var href = window.location.href;
      var path = window.location.pathname.replace(/\/[a-z]{2}/, "/" + val);
      var query = href.split("?")[1] ? `?${href.split("?")[1]}` : "";
      window.location.href = path + query;
    },
    name: (item) => `${item.name}`,
  },
};
</script>