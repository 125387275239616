<template>
<div v-if="student && participant.totalScore > 0" class="d-flex justify-center">
  <v-card class="mb-3" max-width="316">
    <v-card-title>{{$t('view.PageMainEventScoreboard.highlight_title')}}</v-card-title>
    <div class="mx-2 d-relative" style="width:300px;height:300px;">
      <v-img
      v-if="gameCode == 'FISH'"
        class="d-absolute"
        width="300"
        height="300"
        :src="require('@/assets/Game/News/1/Background.png')"
      ></v-img>
      <v-img
      v-else-if="gameCode == 'DASH'"
        class="d-absolute"
        width="300"
        height="300"
        :src="require('@/assets/Game/News/3/Background.png')"
      ></v-img>
      <div 
        class="d-absolute d-flex justify-center pa-3"
        style="left:0;right:0;top:0;margin:auto;" 
      >
        <span v-if="gameCode == 'FISH'" class="text-h5 font-weight-black" style="-webkit-text-stroke: 0.5px white;">{{$t('view.PageMainEventScoreboard.highlight_1_shout')}}</span>
        <span v-else-if="gameCode == 'DASH'" class="text-h5 font-weight-black" style="-webkit-text-stroke: 0.5px white;">{{$t('view.PageMainEventScoreboard.highlight_2_shout')}}</span>
      </div>
      <div 
        class="d-absolute d-flex justify-center pb-3"
        style="left:0;right:0;bottom:0;margin:auto;" 
      >
        <v-chip color="yellow font-weight-bold black--text">
          <span class="text-truncate">{{participant.name}}</span>
        </v-chip>
      </div>
      <AAvatar 
        class="d-absolute"
        style="right:31%;bottom:31%;"
        :avatar="student.avatar"
        :isShowShadow="false"
        :height="110"
        :width="110"
      ></AAvatar>
      <v-img
      v-if="gameCode == 'FISH'"
        class="d-absolute"
        width="300"
        height="300"
        :src="require('@/assets/Game/News/1/Boat.png')"
      ></v-img>
    </div>
    <v-card-text  v-if="gameCode == 'FISH'">{{participant.name}} ({{student.Classroom.name}}) {{$t('view.PageMainEventScoreboard.highlight_1_content')}}</v-card-text>
    <v-card-text  v-else-if="gameCode == 'DASH'">{{participant.name}} ({{student.Classroom.name}}) {{$t('view.PageMainEventScoreboard.highlight_2_content')}}</v-card-text>
  </v-card>
</div>
</template>
<style scoped>
  .d-absolute {
    position: absolute;
  }
  .d-relative {
    position: relative;
  }
</style>
<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
      'gameCode'
    ],
    data: () => ({
      student:null,
    }),
    created() {
      //
    },
    mounted() {
      var sorted = this.$_.orderBy(this.data,['totalScore'],['desc'])
      const { Student, ...Participant } = sorted[0]
      this.participant = Participant
      this.student = Student
    },
    methods: {
      //
    }
  }
</script>