<template>
  <v-card-text>
    <ol class="list">
      <li>学生可以如何参与此活动呢？</li>
      <ul>
        <li>这活动会使用Hamochi网站进行。</li>
        <li>从{{$moment(data.timestampStart).locale($_getLocale()).format("DD/MM/YYYY")}}开始至{{$moment(data.timestampEnd).locale($_getLocale()).format("DD/MM/YYYY")}}结束。总共{{$moment(data.timestampEnd).diff($moment(data.timestampStart),'days')+1}}天。</li>
        <li>
          在活动期间的任何一天早上8时至晚上11时，学生可以使用个人手机或电脑<a @click="joinNow()" class="text-decoration-underline">点击这里参与活动</a>。
        </li>
        <li v-if="$route.query.s">学校代码是：{{$route.query.s}}</li>
      </ul>
      <li>如何使用Hamochi呢？</li>
      <ul>
        <template v-if="data.Game && data.Game.code == 'DASH'">
          <li><a target="_blank" href="https://youtu.be/WfqKg8Q1Ktw">观看完整版教程视频</a>。</li>
          <li>如果网速慢，可以使用简易版。<a target="_blank" href="https://youtu.be/52hq5xYi3qE">观看简易版教程视频</a>。</li>
        </template>
        <li v-else><a target="_blank" href="/pdf/Hamochi Manual 2022 (ZH).pdf">点击这里阅览使用手册</a>。</li>
      </ul>
      <li>此活动的目的是什么呢？</li>
      <ul>
        <li>
          提升学生们对学习的兴趣，鼓励他们复习并运用已经学会的知识。
        </li>
      </ul>
      <li>此活动与其他数学比赛有什么不同之处？</li>
      <ul>
        <li>
          学生们在活动期间依然可以查阅课本等寻求正确的解答方法。
        </li>
        <li>
          学生们如果对自身表现不满意可以不断尝试解答同个课题的不同问题。
        </li>
      </ul>
      <li>活动前需要怎么样的准备吗？</li>
      <ul>
        <li>学生们可以在活动前预习。<a @click="changeTab('topics')" class="text-decoration-underline">点击这里查看这次的课题</a>。</li>
      </ul>
      <li>监护人须扮演怎么样的角色呢？</li>
      <ul>
        <li>我们鼓励监护人与学生共同参与此活动。</li>
        <li>
          监护人的角色十分重要。小孩子是通过模仿身边的大人学习的。
        </li>
        <li>监护人可以在活动开始时确保学生可以参与活动。</li>
        <li>监护人可以在活动期间鼓励学生解答所有的课题。</li>
        <li>
          如果学生遇到什么学术上的困难，监护人可以指导学生解答。
        </li>
        <li>但是我们不鼓励监护人使用手机或电脑直接干预学生解答。</li>
      </ul>
      <li>比赛如何计分呢？</li>
      <ul>
        <li v-if="data.Checkpoint">比赛会有{{data.Checkpoint.length}}个课题。</li>
        <li>每个课题学生必须解答10道数学题。</li>
        <li>每答对一题可获得80分。</li>
        <li>系统会自动统计各课题的最高得分。</li>
        <li>
          学生如果对自己的表现不满意可以再次回答同个课题来取得更高的得分。
        </li>
      </ul>
      <ul v-if="data.Game && data.Game.code == 'FISH'">
        <b>以下规则只适用于这次活动的游戏「Fishing Frenzy」：</b>
        <li>每回答一题后，学生可以玩一场小游戏。</li>
        <li>在小游戏里学生最多可以获得20分。</li>
        <li>每个课题会有一只最多80分的神秘鱼等待学生发现。</li>
      </ul>
      <ul v-else-if="data.Game && data.Game.code == 'DASH'">
        <b>以下规则只适用于这次活动的游戏「Bug Catcher」：</b>
        <li>在游戏里可以捕捉蝴蝶。</li>
        <li>每抓一只蝴蝶可得1分。</li>
        <li>解答速度、游戏难度、神秘昆虫等也会稍微影响分数。</li>
      </ul>
      <li>学生可以重复解答同样的课题吗？</li>
      <ul>
        <li>可以。我们十分鼓励学生多做复习。</li>
        <li>
          别担心。为防学生盲目背诵答案，每次学生开始回答时，系统会自动制作不同的问题。
        </li>
      </ul>
      <li>为什么每次回答时数学题都会不同呢？</li>
      <ul>
        <li>Hamochi系统会每次自动制作不同的数学题。</li>
        <li>有两个主要原因。</li>
        <li>第一，为了防止学生盲目的背诵答案。</li>
        <li>第二，为了防止学生抄袭。</li>
        <li>我们鼓励学生学习并了解正确的解答法。</li>
      </ul>
      <li>监护人可以查看学生的排名吗？</li>
      <ul>
        <li>
          可以。<a @click="changeTab('students')" class="text-decoration-underline">点击这里游览排名。</a>
        </li>
      </ul>
      <li>如果无法登入或遇到其他问题怎么办？</li>
      <ul>
        <li><router-link :to="{name:'PageMainPageTroubleshoot'}">先查看Troubleshoot</router-link></li>
        <!-- <li>如果电脑或手机的岁数有超过8年，请<a @click="joinLite()" target="_blank" class="text-decoration-underline">点击这里试用简易版</a>。</li> -->
        <li>如果依然有问题，请尝试使用其他的电脑或手机。</li>
        <!-- <li>也可以<a href="https://wa.me/+60123989864?text=你好，我在使用Hamochi上遇到了以下的问题。" target="_blank">联络我们</a>。</li> -->
      </ul>
      <li>如果游戏里的问题不正确怎么办？</li>
      <ul>
        <li>请<a href="https://wa.me/+60123989864?text=你好，我在使用Hamochi上遇到了以下的问题。" target="_blank">联络我们</a>。我们会马上更正。</li>
      </ul>
    </ol>
  </v-card-text>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
      'urlLite',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      changeTab(tab) {
        this.$emit('changeTab',tab)
      },
       joinNow() {
        this.$emit('joinNow')
      },
      joinLite() {
        this.$emit('joinLite')
      }
    }
  };
</script>
<style scoped>
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>